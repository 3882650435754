import React from "react";
import { RiLogoutCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import CircleButton from "src/components/CircleButton";
import { apiToken } from "src/state/index";
import { callApi } from "src/utils/api";

const NavLogout: React.FC = () => {
  let navigate = useNavigate();
  const token = useRecoilValue(apiToken);

  const logout = () => {
    // @ts-ignore
    callApi(token, "deleteSession", { authorization: `Bearer ${token}` });

    navigate("/login");
  };

  return (
    <>
      <CircleButton onClick={logout} small>
        <RiLogoutCircleLine size={30} />
      </CircleButton>
    </>
  );
};

export default NavLogout;
