import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import Button from "src/components/Button";
import Card from "src/components/Card";
import { userState } from "src/state/index";
import { CenteringWrapper } from "src/layout/index";
import styled from "styled-components";

const Form = styled.form`
  width: 300px;
  padding: 10px;
  border-radius: 5px;
`;

const Label = styled.label`
  display: block;
  margin-top: 20px;
  margin-bottom: 5px;
  color: white;
  font-weight: 600;
  font-size: 24px;
  line-height: 2;
`;

const Input = styled.input`
  display: block;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 15px;
  border: 1px solid grey;
  border-radius: 4px;
  font-size: 16px;
`;

const Error = styled.p`
  padding: 0 5px;
  color: white;
  font-size: 12px;
`;

type LoginFormData = {
  userId: string;
  password: string;
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const setUserState = useSetRecoilState(userState);
  const [error, setError] = useState("");
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<LoginFormData>();

  const onSubmit = handleSubmit(async ({ userId, password }) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/session`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: userId, password }),
    });

    const content = await response.json();
    const { token } = content;

    if (response.status === 201) {
      setUserState({ isLoggedIn: true, userId, token });
      navigate("/");
    } else {
      setError(content.message);
    }
  });

  return (
    <CenteringWrapper>
      <Card color="#0e101c">
        <Form onSubmit={onSubmit}>
          <Label>Tunnus</Label>
          <Input
            {...register("userId", { min: 0, max: 1000, required: true })}
          />
          <Error>{errors.userId && "Jäsennumero vaaditaan"}</Error>

          <Label>Salasana</Label>
          <Input
            type="password"
            {...register("password", { required: true })}
          />
          <Error>{errors.password && "Salasana vaaditaan"}</Error>

          <Error>{error}</Error>

          <Button disabled={isSubmitting} type="submit">
            Kirjaudu
          </Button>
        </Form>
      </Card>
    </CenteringWrapper>
  );
};

export default Login;
